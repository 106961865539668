.container {
  margin: 0 auto;
  padding-left:  floor(math.div($grid-gutter-width,2));
  padding-right: ceil(math.div($grid-gutter-width,2));
  @include clearfix;

  // @media (min-width: $screen-sm-min) {
  //   width: $container-sm;
  // }
  // @media (min-width: $screen-md-min) {
  //   width: $container-md;
  // }
  // @media (min-width: $screen-lg-min) {
  //   width: $container-lg;
  // }
}

.row {
  margin-left:  ceil(math.div($grid-gutter-width,-2));
  margin-right: floor(math.div($grid-gutter-width,-2));
  @include clearfix;
}

@include make-grid-columns;
@include make-grid(xs);
@media (min-width: $screen-sm-min) {
  @include make-grid(sm);
}
@media (min-width: $screen-md-min) {
  @include make-grid(md);
}
@media (min-width: $screen-lg-min) {
  @include make-grid(lg);
}
