.footer {
    background: $color4;
    &-pic {
        position: relative;
        z-index: 2;
        text-align: center;
        .container {
            padding: 0;
            max-width: none;
            width: auto;
        }
        .pc {
            display: none;
        }
    }
    &-txt {
        padding: 4.5em 0 2.5em;
        position: relative;
        z-index: 1;
        text-align: center;
    }
    &-logo {
        &-group {
            .title, .img {
                display: inline-flex;
                vertical-align: middle;
            }
            .title {
                font-weight: 700;
                letter-spacing: 0.2em;
                white-space: nowrap;
            }
            .img {
                flex-wrap: wrap;
                flex: 1;
                align-items: center;
                max-width: calc(100% - 10em);
                color: inherit;
                text-decoration: none;
                &.nowrap {
                    flex-wrap: nowrap;
                    margin-left: 0.5em;
                }
                .txt {
                    display: inline-block;
                    font-size: 1.2em;
                    font-weight: 700;
                    letter-spacing: 0.2em;
                    margin: 0 0.5em;
                    .footer-follow & {
                        letter-spacing: 0.1em;
                    }
                }
                img {
                    flex: auto;
                    flex-grow: 0;
                    margin: 0.5em 0;
                    max-height: 2.5em;
                }
            }
        }
    }
    &-follow {
        &::after {
            content: '';
            display: block;
            width: 4em;
            height: 2px;
            background: $text-color;
            margin: 1.5em auto 2em;
        }
    }
    &-copyright {
        @include fz(16px);
        font-weight: 700;
        letter-spacing: 0.2em;
        text-align: center;
        padding-top: 2em;
    }
}


@media (min-width: $screen-sm-min) {
    .footer {
        &-pic {
            .pc {
                display: inline-block;
            }
            .m {
                display: none;
            }
        }
        &-txt {
            &::before {
                height: 5px;
                left: percentage($number: math.div(80,1920));
                right: percentage($number: math.div(80,1920));
            }
            .container {
                max-width: 1320px;
            }
        }
    }
}
@media (min-width: $screen-md-min) {
    .footer {
        &-logo {
            &-group {
                display: inline-block;
                vertical-align: middle;
                padding: 0 1em;
                .img {
                    max-width: none;
                }
            }
        }
    }
}
@media (min-width: $screen-lg-min) {
    .footer {
        &-txt {
            &::before {
                height: 8px;
            }
        }
    }
}
@media (min-width: $screen-xl-min) {
}
