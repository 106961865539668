.btn {
  @include btn-reset;
  transition: 0.3s;
  &:hover {
    text-decoration: none;
  }
}

@media (min-width: $screen-sm-min) {
  .btn {
  }
}
@media (min-width: $screen-md-min) {
  .btn {
  }
}
@media (min-width: $screen-lg-min) {
}
