.entry-list {
    li {
        position: relative;
        padding-left: 1.75em;
        margin-bottom: 0.75em;
        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0.2em;
            background: url('../img/list_deco_1.svg') no-repeat;
            background-size: 100% auto;
            width: math.div(24,18) + 0em;
            height: math.div(24,18) + 0em;
        }
    }
    li:nth-child(2n) {
        &::before {
            background-position: 0 100%;
        }
    }
}

.traffic-layout {
    .map {
        &-embed {
            position: relative;
            min-height: 288px;
            &::before {
                content: '';
                display: block;
                padding-top: 275px;
                pointer-events: none;
            }
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 5px solid #DDC974;
                pointer-events: none;
            }
            img.cke_iframe,
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
    .txt {
        padding: 1.5em;
    }
    .hr {
        border: 0;
        border-top: 3px solid $text-color;
        margin: 1.5em -0.5em;
    }
}
.traffic-title {
    @include fz(18px);
    font-weight: 700;
    letter-spacing: 0.2em;
    margin: 1em 0 0.3em;
    padding-left: 2.25em;
    position: relative;
    &:first-child {
        margin-top: 0.3em;
    }
    .icon {
        display: block;
        position: absolute;
        top: 0.1em;
        left: 0;
        background: url('../img/traffic_icons.svg') no-repeat;
        background-size: 100% auto;
        width: 1.66em;
        height: 1.66em;
        &.ic1 {
            background-position: 0 0;
        }
        &.ic2 {
            background-position: 0 50%;
        }
        &.ic3 {
            background-position: 0 100%;
        }
    }
}
.traffic-title2 {
    @include fz(16px);
    font-weight: 700;
    text-decoration: underline;
    margin: 0.5em 0;
    padding-left: 2.4em;
    position: relative;
    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0.55em;
        top: 0.35em;
        background: url('../img/workshop_icon1.png') no-repeat;
        background-size: contain;
        width: math.div(21,18) + 0em;
        height: math.div(19,18) + 0em;
    }
}
.traffic-list {
    li {
        position: relative;
        padding-left: 2.8em;
        margin-bottom: 0.5em;
        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0.61em;
            top: 0.4em;
            background: url('../img/list_deco_2.svg') no-repeat;
            background-size: contain;
            width: math.div(21,18) + 0em;
            height: math.div(19,18) + 0em;
        }
    }
}

@media (min-width: $screen-sm-min) {
}
@media (min-width: $screen-md-min) {
}
@media (min-width: $screen-lg-min) {
    .traffic-layout {
        display: flex;
        .map {
            flex-grow: 0;
            flex-shrink: 0;
            width: percentage($number: math.div(649,1600));
            margin-bottom: 0;
            &-embed {
                height: 100%;
            }
        }
        .txt {
            padding: 3em;
            flex: auto;
        }
    }
}
@media (min-width: $screen-xl-min) {
}
