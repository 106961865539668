@keyframes decoNote {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
        margin-top: 0;
    }

    20% {
        margin-top: 20px;
    }

    25% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }

    50% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    60% {
        margin-top: 0px;
    }

    75% {
        transform: translate(-50%, -50%) rotate(5deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
}

.deco-note {
    animation: decoNote 2s infinite;
}

@media screen and (min-width: $screen-sm-min) {
    .anime {
        visibility: hidden;

        &.anime-run {
            visibility: visible;
        }
    }

    .fade-in-up.anime-run {
        @include fadeInUp($duration: 1s, $delay: 0.1s);
    }

    .fade-in.anime-run {
        @include fadeIn($duration: 1s, $delay: 0.2s);
    }

    .brand-list,
    .workshop-list {
        .item.anime-run {
            @include fadeIn($duration: 0.5s);

            @for $i from 1 through 6 {
                &:nth-child(#{$i}) {
                    animation-delay: #{$i * 0.1 - 0.1}s;
                }
            }
        }
    }
}

@media screen and (min-width: $screen-md-min) {
    .brand-list,
    .workshop-list {
        .item.anime-run {
            @for $i from 1 through 8 {
                &:nth-child(#{$i}) {
                    animation-delay: #{$i * 0.1 - 0.1}s;
                }
            }
        }
    }
}

@media screen and (min-width: $screen-xl-min) {
    .brand-list,
    .workshop-list {
        .item.anime-run {
            @for $i from 1 through 12 {
                &:nth-child(#{$i}) {
                    animation-delay: #{$i * 0.1 - 0.1}s;
                }
            }
        }
    }
}
