.news-detail-mfp {
    &.mfp-bg {
        background: $color2 url('../img/timetable_bg.png') repeat;
        opacity: 1;
    }
    &.mfp-wrap {
        padding: 1em;
    }
    .mfp-close,
    .mfp-close:active {
        @include txt-hide;
        background: url('../img/close.svg') no-repeat 50% 50%;
        background-size: 50% auto;
        font-size: 1em;
        width: 2.8em;
        height: 2.8em;
        opacity: 1;
        top: 1.5em;
        right: 1.25em;
    }
    .mfp-container {
        padding: 2em;
        position: relative;
        display: flex;
        .mfp-content {
            flex: auto;
            margin: auto;
            overflow-y: auto;
            max-height: 100%;
            max-width: 800px;
        }
    }
    .mCSB_scrollTools {
        opacity: 1;
        width: 32px;
    }
    .mCSB_outside+.mCSB_scrollTools {
        right: -32px;
    }
    .mCS-dark.mCSB_scrollTools {
        .mCSB_draggerRail {
            background-color: transparent;
        }
        .mCSB_dragger .mCSB_dragger_bar {
            // background-color: rgba($color2,0.75);
            background-color: #fff;
        }
        .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
        .mCSB_dragger:active .mCSB_dragger_bar,
        .mCSB_dragger:hover .mCSB_dragger_bar {
            background-color: #fff;
        }
    }
}

@media (min-width: $screen-sm-min) {
    .news-detail-mfp {
        &.mfp-wrap {
            padding: percentage($number: math.div(60,1920)) percentage($number: math.div(64,1920));
        }
        .mfp-container {
            padding: 3.8em;
        }
        .mfp-close,
        .mfp-close:active {
            top: 2.8em;
            right: 3em;
            background-size: contain;
        }
    }
}
@media (min-width: $screen-md-min) {
}
@media (min-width: $screen-lg-min) {
    .news-detail-mfp {
        .mfp-close,
        .mfp-close:active {
            top: 3.8em;
            right: 4em;
        }
        .mfp-container {
            .mfp-content {
                flex: 68% 0 0;
            }
        }
    }
}
@media (min-width: $screen-xl-min) {
}
@media (min-width: $screen-xxl-min) {
    .news-detail-mfp {
        .mfp-close,
        .mfp-close:active {
            top: 4.8em;
            right: 5em;
        }
    }
}
