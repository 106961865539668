.table-wrap {
  overflow-x: auto;
}
.table {
  @include table-reset;
  width: 100%;
  th, td {
    text-align: center;
    border: 1px solid #ddd;
    padding: 5px;
    line-height: 1.4;
  }
}
