.news-detail {
    position: relative;
    color: $text-color;
    .container {
        padding: 0;
    }
    .title {
        @include fz(18px);
        font-weight: 700;
        line-height: math.div(25,18);
        background: url('../img/news_border2.png') repeat-x 0 100%;
        background-size: auto 3px;
        padding-bottom: 1em;
        margin: 0.75em 0;
    }
    .txt {
        padding: 1px 1.5em 1.5em;
        background: #fff;
    }
}


@media (min-width: $screen-sm-min) {
    .news-detail {
        .title {
            @include fz(25,16);
            background-size: auto 5px;
            margin: 1em 0;
            padding-bottom: 1em;
        }
    }
}
@media (min-width: $screen-md-min) {
}
@media (min-width: $screen-lg-min) {
    .news-detail {
        .title {
            background-size: auto 8px;
            margin: 1.25em 0;
            padding-bottom: 1.25em;
        }
    }
}
@media (min-width: $screen-xl-min) {
}
