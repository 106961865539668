


@media (min-width: $screen-sm-min) {
}
@media (min-width: $screen-md-min) {
}
@media (min-width: $screen-lg-min) {
    .wrapper {
        padding-right: 65px;
    }
}
@media (min-width: $screen-xl-min) {
}
