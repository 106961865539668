.list,
.list-h,
.list-v,
.list-inline {
  &,
  ul {
    @include list-reset;
  }
  a, .box {
    display: block;
    text-decoration: none;
    color: inherit;
    span {
      display: block;
      transition: 0.3s;
    }
  }
}
.list-h {
  @include clearfix;

  > li,
  .item {
    float: left;
  }
}
.list-inline {
  > li {
    display: inline-block;
    vertical-align: top;
  }
  a {
    display: inline-block;
  }
}
