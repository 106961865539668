.sect {
    position: relative;
    &-heading {
        &-wrap {
            text-align: left;
            position: relative;
            z-index: 10;
            max-width: 1330px;
            margin: 0 auto 2em;
            .deco,
            .location {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                max-width: none;
            }
        }
        display: inline-block;
        vertical-align: middle;
        font-size: 1em;
        line-height: 1;
        margin: 0;
        img {
            max-height: 2em;
        }
    }
    .container {
        width: percentage($number: math.div(275,375));
        padding: 4.5em 0;
    }
}
.bg1 {
    background-color: $color1 !important;
}
.bg2 {
    background-color: $color2 !important;
}
.bg3 {
    background-color: $color3 !important;
}
.bg4 {
    background-color: $color4 !important;
}
.banner-sect {
    .container {
        padding: 0 !important;
        width: auto;
    }
    .scroll {
        position: absolute;
        z-index: 10;
        bottom: 0;
        left: 50%;
        transform: translate(-50%,65%);
    }
}
.banner-pic,
.map-pic {
    margin: 0;
    img {
        width: 100%;
    }
    &.pc {
        display: none;
    }
    &.m {
        margin: 0;
    }
}
.news-about-bg {
    background: $color1 url('../img/news_about_bg.png') repeat 50% 0;
}
.news-sect {
    padding-top: 3em;
    .container {
        padding-bottom: 0 !important;
    }
}
.news-slider {
    margin: 0 -15px;
    .item {
        padding: 0 15px;
    }
    .slick-prev,
    .slick-next {
        @include btn-reset;
        @include txt-hide;
        background-image: url('../img/slider_arrows.svg');
        background-size: 200% 200%;
        width: 2.8em;
        height: 2.8em;
        border-radius: 50%;
        position: absolute;
        z-index: 10;
        top: 50%;
        margin-top: -1.4em;
    }
    .slick-prev {
        left: -2.25em;
        background-position: 0 0;
        &:hover {
            background-position: 0 100%;
        }
    }
    .slick-next {
        right: -2.25em;
        background-position: 100% 0;
        &:hover {
            background-position: 100% 100%;
        }
    }
}
.news-box {
    background: $color2 url('../img/timetable_bg.png') repeat;
    padding: 26px 24px 22px;
    max-width: 420px;
    margin: 0 auto;
    a {
        color: inherit;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .pic {
        img {
            width: 100%;
        }
    }
    .txt {
        background: #fff;
        padding: 1em;
    }
    .title {
        @include fz(16px);
        font-weight: 700;
        line-height: math.div(26,16);
        background: url('../img/news_border.png') no-repeat 0 100%;
        background-size: auto 4px;
        margin: 0 0 0.5em;
        padding-bottom: 0.75em;
        a {
            display: block;
            @include multi-line(2);
        }
    }
    .desc {
        @include multi-line(3, $line-height-base, true);
        margin-bottom: 0;
    }
}
.about-sect {
    padding-bottom: 2em;
    &.open {
        background: url('../img/about_bg_m.png') no-repeat 50% 100%;
        background-size: 100% auto;
        padding-bottom: percentage($number: math.div(234,750));
    }
}
.about-content {
    position: relative;
    @include fz(15px);
    color: #fff;
    .desc,
    .editor {
        max-width: 420px;
        line-height: math.div(22,15);
        margin: 0 auto;
    }
    .desc {
        @include multi-line(7);
    }
    .editor {
        display: none;
    }
    &-more {
        margin: 0 auto;
        padding: 3.5em 0 0;
        text-align: center;
        .btn {
            &::before {
                content: '';
                display: inline-block;
                vertical-align: text-bottom;
                background: url('../img/more_arrow.png') no-repeat;
                background-size: contain;
                width: math.div(100,15) + 0em;
                height: math.div(22,15) + 0em;
                margin-right: 0.5em;
                position: relative;
                top: 0.15em;
            }
        }
    }
    &.open {
        .desc {
            display: none;
        }
        .editor {
            display: block;
        }
        .about-content-more {
            display: none;
        }
    }
}
.workshop-sect {
    background: $color2 url('../img/workshop_bg.png') repeat 50% 0;
}
.artists-sect {
    background: $color3 url('../img/artists_bg.png') repeat 50% 0;
    .sect-heading-wrap {
        margin-bottom: 2.5em;
    }
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url('../img/artists_deco_m.png') no-repeat 50% 0;
        background-size: 100% auto;
    }
    .deco {
        display: none;
    }
}
.artists-content {
    .has-heading {
        padding-top: 0;
    }
    &-inner {
        position: relative;
        z-index: 3;
    }
}
.artists-heading {
    color: #fff;
    text-align: left;
    margin: 0 1em 1.5em;
    @include fz(20px);
    line-height: 1.5;
    display: none;
    .has-heading & {
        display: block;
    }
    span {
        display: block;
    }
}
.workshop-date,
.artists-date,
.timetable-date,
.market-date {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: $font-family-sans-serif2;
        font-size: 1.07em;
        font-weight: 400;
        width: 4.1em;
        height: 4.1em;
        text-align: center;
        border-radius: 50%;
        color: #fff;
        background: $text-color;
        border: 2px solid $text-color;
        margin: 0 0.3em;
        &.active {
            color: $text-color;
            background-color: #fff;
        }
    }
}
.artists-date {
    margin-bottom: 2em;
}
.artists-time,
.timetable-time {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: auto;
    padding: 0;
    img {
        display: block;
        max-height: 100%;
    }
}
.artists-slider,
.timetable-slider {
    &-wrap {
        position: relative;
        z-index: 5;
        img {
            margin: 0 auto;
        }
    }
    .item {
        padding: 0;
        vertical-align: top;
        img {
            margin: 0 auto;
            &.pc {
                display: none;
            }
            &.m {
                display: block;
            }
        }
    }
}
.no-time {
    .artists-time,
    .timetable-time {
        display: none !important;
    }
    &.artists-slider-wrap,
    &.timetable-slider-wrap {
        padding-left: 0 !important;
    }

}
.timetable-sect {
    padding-bottom: 6%;
    background: $color2 url('../img/timetable_bg.png') repeat 50% 0;
}
.timetable-content {
    position: relative;
    background: #fff;
    padding: 3.5em 10% 2.5em;
    margin-top: 5em;
}
.timetable-date {
    position: absolute;
    z-index: 2;
    top: -2px;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}
.timetable-slider {
    background: url('../img/timetable_slider_bg.png') repeat-x 50% 0;
    background-size: auto 100%;
}
.market-sect {
    padding-bottom: 6%;
    color: #fff;
    background: $color1 url('../img/market_bg.png') repeat 50% 0;
    .deco {
        display: none;
    }
}
.market-date {
    &-hint {
        text-align: center;
        font-size: 1.25em;
        font-weight: 700;
        margin-bottom: 1em;
    }
    margin-bottom: 2em;
    .btn {
        .week {
            display: none;
        }
    }
}
.workshop-brand,
.market-brand {
    display: none;
    &.active {
        display: block;
    }
}
.market-list {
    position: relative;
    z-index: 2;
    margin: 0 -7px;
    @include clearfix;
    .item {
        padding: 0 7px;
        float: left;
        width: 50%;
        &:nth-child(2n+1) {
            clear: left;
        }
    }
}
.market-box {
    text-align: center;
    margin: 0 auto 1.5em;
    .pic {
        margin-bottom: 0.5em;
        position: relative;
        &::before {
            content: '';
            display: block;
            padding-top: 100%;
        }
        a, img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
        }
        img {
            object-fit: cover;
            border-radius: 50%;
        }
    }
    .title {
        @include fz(16px);
        font-weight: 700;
        line-height: 1.25;
        margin: 0.5em 0 0;
    }
}

.map-content {
    margin-left: -10%;
    margin-right: -10%;
}
.entry-sect {
    background: $color2 url('../img/entry_bg.png') repeat 50% 0;
}
.entry-content {
    max-width: 1150px;
    margin: 0 auto;
}
.traffic-sect {
    background: $color3 url('../img/traffic_bg.png') repeat 50% 0;
}
.traffic-content {
    position: relative;
    background: #fff;
    .deco {
        display: none;
    }
}

.workshop-sect {
    .sect-heading-wrap {
        margin-bottom: 1.5em;
    }
    .deco {
        display: none;
    }
}
.workshop-date {
    justify-content: flex-start;
    margin-bottom: 1.2em;
}
.workshop-box {
    line-height: 1.5;
    max-width: 460px;
    margin: 0 auto 40px;
    a {
        display: block;
    }
    .title {
        @include fz(18px);
        font-weight: 500;
        margin: 0 0 0.75em;
        color: #fff;
        background: $color1;
        padding: 0.25em 0.5em;
        a {
            @include multi-line(2, 1.5);
            &:hover {
                color: $color2;
            }
        }
    }
    .brand {
        position: relative;
        color: $color1;
        margin: 0 0 0.25em;
        padding-left: math.div(24,17) + 0em;
        @include fz(17px);
        font-weight: 700;
        &::before {
            content: '';
            display: block;
            background: url('../img/workshop_icon1.svg') no-repeat;
            background-size: contain;
            width: 1em;
            height: 1em;
            position: absolute;
            left: 0;
            top: 0.3em;
        }
    }
    .time {
        position: relative;
        font-weight: 700;
        margin: 0 0 0.5em;
        padding-left: math.div(24,14) + 0em;
        &::before {
            content: '';
            display: block;
            background: url('../img/workshop_icon2.svg') no-repeat;
            background-size: contain;
            width: math.div(17,14) + 0em;
            height: math.div(17,14) + 0em;
            position: absolute;
            left: 0;
            top: 0.2em;
        }
    }
    .desc {
        @include fz(15px);
        @include multi-line(7,1.5);
    }
    .more {
        background: #fff;
        color: #000;
        font-weight: 700;
        max-width: 12.7em;
        line-height: 2.4;
        text-align: center;
        border: 2px solid #000;
        &:hover {
            background: #000;
            color: #fff;
        }
    }
}

@media (min-width: $screen-sm-min) {
    .sect-heading {
        &-wrap {
            margin-bottom: 2em;
        }
        img {
            max-height: 3em;
        }
    }
    .banner-sect {
        .scroll {
            display: none;
        }
    }
    .banner-pic,
    .map-pic {
        &.m {
            display: none;
        }
        &.pc {
            display: block;
        }
    }
    .news-sect {
        .container {
        }
    }
    .news-slider {
        max-width: 1400px;
        margin: 0 auto;
        // padding: 0 2em;
        .slick-prev {
            left: -3.8em;
        }
        .slick-next {
            right: -3.8em;
        }
    }
    .about-sect {
        &.open {
            background-image: url('../img/about_bg.png');
            padding-bottom: percentage($number: math.div(286,1855));
        }
    }
    .about-content {
        .desc,
        .editor {
            max-width: none;
            padding: 0;
        }
        &-more {
            padding: 3.5em 0 0;
            text-align: right;
        }
    }
    .artists-sect {
        z-index: 2;
        padding-bottom: percentage($number: math.div(320,1855));
        &::before {
            background-image: url('../img/artists_deco.png');
        }
        .deco {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            transform: translateY(#{percentage(math.div(111,510))});
        }
    }
    .timetable-sect {
        padding-top: 5%;
    }
    .artists-date,
    .timetable-date {
        .btn {
            margin: 0 1em;
            font-size: 1.25em;
        }
    }
    .artists-heading {
        margin-left: 1.5em;
        margin-right: 1.5em;
    }
    .artists-content,
    .timetable-content {
        margin-left: -8%;
        margin-right: -8%;
    }
    .market-sect,
    .entry-sect,
    .traffic-sect {
        .sect-heading-wrap {
            margin-bottom: 3em;
        }
    }
    .market-sect {
        position: relative;
        z-index: 2;
        padding-top: 5%;
        .deco {
            display: block;
            position: absolute;
        }
        .deco1 {
            width: percentage($number: math.div(611,1855));
            top: 0;
            right: percentage($number: math.div(118,1855));
            transform: translateY(#{percentage($number: math.div(-122,567))});
        }
        .deco2 {
            width: percentage($number: math.div(654,1855));
            bottom: 0;
            left: 0;
            transform: translate(#{percentage($number: math.div(-31,654))}, #{percentage($number: math.div(212,386))});
        }
    }
    .market-date {
        position: relative;
        margin-bottom: 3em;
        .btn {
            width: 4em;
            height: 4em;
            flex-direction: column;
            font-size: 1.5em;
            line-height: 1.25;
            padding-top: 0.2em;
            .week {
                display: block;
                font-family: $font-family-sans-serif4;
                @include fz(28,36);
                font-weight: 600;
            }
        }
    }
    .market-list {
        margin: 0 -1em;
        .item {
            width: 33.33%;
            padding: 0 1em;
            &:nth-child(2n+1) {
                clear: none;
            }
            &:nth-child(3n+1) {
                clear: left;
            }
        }
    }
    .entry-sect {
        padding-top: 5%;
    }
    .entry-content {
        padding: 0 2.25em;
    }

    .workshop-sect {
        .deco {
            display: block;
            width: percentage(math.div(386,1330));
            position: absolute;
            z-index: 10;
            bottom: 0;
            right: 0;
            transform: translate(15%,40%);
        }
    }
    .workshop-box {
        max-width: none;
        @include clearfix;
        .col1 {
            float: left;
            width: 300px;
        }
        .col2 {
            margin-left: 330px;
        }
        .desc {
            margin-left: math.div(24,15) + 0em;
            margin-bottom: 2em;
        }
    }
}
@media (min-width: $screen-md-min) {
    .sect-heading-wrap {
        font-size: 1.25em;
    }
    .news-box {
        .title {
            background-size: auto 5px;
        }
    }
    .about-content {
        &-more {
        }
    }
    .artists-heading {
        @include fz(30,18);
        span {
            display: inline-block;
        }
    }
    .artists-slider,
    .timetable-slider {
        &-wrap {
            max-width: 1540px;
            margin: 0 auto;
        }
        .item {
            img {
                &.pc {
                    display: block;
                }
                &.m {
                    display: none;
                }
            }
        }
    }
    .market-date {
        margin-bottom: 3em;
    }
    .market-content {
    }
    .market-list {
        .item {
            width: 25%;
            &:nth-child(3n+1) {
                clear: none;
            }
            &:nth-child(4n+1) {
                clear: left;
            }
        }
    }
    .market-box {
        margin-bottom: 3em;
    }
}
@media (min-width: $screen-lg-min) {
    .sect {
        .container {
            padding: 8em 0;
        }
    }
    .news-slider {
        .slick-prev,
        .slick-next {
            width: 3.6em;
            height: 3.6em;
            margin-top: -1.8em;
        }
        .slick-prev {
            left: -4.6em;
        }
        .slick-next {
            right: -4.6em;
        }
    }
    .about-content {
        .desc {
            @include multi-line(4);
        }
        .desc,
        .editor,
        &-more {
            max-width: 1170px;
        }
    }
    .artists-sect {
        .sect-heading-wrap {
            margin-bottom: 4em;
        }
    }
    .artists-date,
    .timetable-date {
        display: none;
    }
    .timetable-content {
        padding-left: 7%;
        padding-right: 7%;
    }
    .timetable-content,
    .market-content {
        margin-left: -6%;
        margin-right: -6%;
    }
    .market-date {
        &-hint {
            @include fz(22,18);
        }
        width: 86%;
        max-width: 1320px;
        margin: 0 auto 4em;
        .btn {
            @include fz(27,18);
        }
    }
    .traffic-content {
        margin: 0 -8%;
        .deco {
            display: block;
            position: absolute;
            right: 0;
            bottom: 100%;
            transform: translateY(#{percentage(math.div(92,390))});
            width: percentage($number: math.div(394,1625));
        }
        > * {
            position: relative;
            z-index: 1;
        }
    }
}
@media (min-width: $screen-xl-min) {
    .news-box {
        .title {
            background-size: auto 8px;
        }
    }
    .artists-heading {
        @include fz(40,18);
        margin-bottom: 1.5em;
    }
    .artists-content-inner {
        margin: 0 -4%;
    }
    .market-sect,
    .entry-sect,
    .traffic-sect {
        .sect-heading-wrap {
            margin-bottom: 4em;
        }
    }
    .market-list {
        max-width: 1530px;
        margin: 0 auto;
        .item {
            width: 16.66%;
            &:nth-child(4n+1) {
                clear: none;
            }
            &:nth-child(6n+1) {
                clear: left;
            }
        }
    }
    .workshop-date {
        font-size: 2em;
    }
    .workshop-box {
        .col1 {
            width: 460px;
        }
        .col2 {
            margin-left: 505px;
        }
        .title {
            @include fz(30,18);
            line-height: math.div(35,30);
            padding: 0.5em 0.65em;
        }
        .brand {
            @include fz(34,18);
            padding-left: math.div(46,34) + 0em;
            margin-bottom: 0.45em;
            &::before {
                width: math.div(30,34) + 0em;
                height: math.div(30,34) + 0em;
            }
        }
        .time {
            @include fz(28,18);
            padding-left: math.div(46,28) + 0em;
            margin-bottom: 0.75em;
            &::before {
                width: math.div(30,28) + 0em;
                height: math.div(30,28) + 0em;
            }
        }
        .desc {
            font-size: 1em;
            margin-left: math.div(46,18) + 0em;
            margin-bottom: 3em;
        }
        .more {
            @include fz(20,18);
        }
    }
}
@media (min-width: $screen-xxl-min) {
    .news-slider {
        .slick-prev,
        .slick-next {
            width: 5.5em;
            height: 5.5em;
            margin-top: -2.75em;
        }
        .slick-prev {
            left: -7.5em;
        }
        .slick-next {
            right: -7.5em;
        }
    }
    .news-box {
        padding: 40px 36px 30px;
    }
    .market-date {
        &-hint {
            @include fz(28,18);
        }
        margin-bottom: 5em;
        .btn {
            @include fz(36,18);
        }
    }
}
