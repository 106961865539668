* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}
body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
  background-color: $body-bg;
  letter-spacing: $letter-spacing-base;
  word-wrap: break-word;
  word-break: normal;
}
a {
  transition: 0.3s;
}
p {
  margin: 0 0 1.5em;
  &:last-child {
      margin-bottom: 0;
  }
}
input,
button,
select,
textarea,
a {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
img {
  display: inline-block;
  max-width: 100%;
  height: auto !important;
  vertical-align: middle;
}
.pic img {
  display: block;
  margin: 0 auto;
}
.ic {
  display: inline-block;
  letter-spacing: 0;
}
[role="button"] {
  cursor: pointer;
}
*:focus {
  outline: none;
}
.wrapper {
  overflow: hidden;
}
.container {
  position: relative;
}
.slick-item + .slick-item {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}
.slick-initialized .slick-item + .slick-item {
  position: relative;
  visibility: visible;
}
.main,
.container,
.clearfix {
  @include clearfix;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
.hide {
  display: none !important;
}

@media (min-width: $screen-md-min) {
  body {
    font-size: 16px;
  }
}
@media (min-width: $screen-xl-min) {
  body {
    font-size: 18px;
  }
}
